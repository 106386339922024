/* eslint-disable eqeqeq */
export const useQueryNavigationPath = (
  query: Record<string, any>,
  exact = false,
  path?: string
) => {
  const route = useRoute()

  if (exact) {
    // Remove null, empty or ALL repeated params
    let matches = 0

    const temp: Record<string, any> = {}

    Object.keys(query).forEach((key) => {
      if (route.query[key] == query[key]) {
        temp[key] = undefined
        matches++
      } else if (query[key] === null || query[key] === '') {
        temp[key] = undefined
      } else {
        temp[key] = query[key]
      }
    })

    if (matches === Object.keys(query).length) {
      query = temp
    }
  } else {
    // Remove null, empty or ANY repeated params
    Object.keys(query).forEach((key) => {
      if (route.query[key] == query[key]) {
        query[key] = undefined
      } else if (query[key] === null || query[key] === '') {
        query[key] = undefined
      }
    })
  }

  return {
    path: path || route.path,
    query: {
      ...route.query,
      ...query
    }
  }
}

export const useQueryNavigation = (
  query: Record<string, any>,
  exact = false,
  method = 'replace',
  path?: string
) => {
  const newPath = useQueryNavigationPath(query, exact, path)
  const localePath = useLocalePath()
  // @ts-ignore
  useRouter()[method](
    localePath(newPath)
  )
}

export const useRouterKeepQuery = (routerPath: any) => {
  const localePath = useLocalePath()
  useRouter().push(localePath({ path: routerPath, query: { branch_id: useRoute().query.branch_id } }))
}
